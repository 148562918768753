.fullscreenContainer {
    background-color: #E1F1FF;
}

.middleContainer {
    display: flex;
    height: 110px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1210px;
    padding: 0 50px;
    width: 100%;
}

.nav {
    align-items: center;
    display: flex;
    list-style: none outside;
    margin: 0
}

.navItem {
    align-items: center;
    display: flex;
    height: 100%;
}

.navLink {
    color: #929292;
    font-size: 13px;
    font-weight: 600;
    margin: 0 10px;
    text-decoration: none;
}

.selected {
    color: #5e5e5e;
}

.navItem:hover,
.selectedBottom {
    border-bottom: 2px solid #0065bd;
    box-sizing: border-box;
    padding-top: 2px;
}

.button {
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: #929292;
    font-size: 13px;
    font-weight: 600;
    height: 100px;
    line-height: 100px;
    outline: none;
    padding: 0 13px;
    text-decoration: none;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border-color 0.4s ease-in-out;
}

.span {
    background: linear-gradient(to bottom, #2b7fc8 0%,#1c76c4 32%,#0065bd 69%);
    border-radius: 2px;
    border: 2px solid #5e5e5e;
    color: #ffffff;
    font-weight: bold !important;
    padding: 9px 25px;
    text-align: center;
}
