.fullscreenContainer {
    background-color: #0065bd;
}
.container {
    color: white;
    font-size: 11px;
    font-weight: 600;
    margin: 0 auto;
    max-width: 1210px;
    padding: 8px 50px;
    text-align: right;
}

.item {
    color: white;
    font-size: 11px;
    padding: 0 10px;
    text-decoration: none;
}

