.fullscreenContainer {
    background-color: #0065bd;
}
.container {
    line-height: 2.3rem;
    margin: 0 auto;
    max-width: 1210px;
    padding: 10px 50px;
}

.container > a {
    color: white;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}